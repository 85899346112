<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    width="500"
  >
    <template #activator="{ on, attrs }">
      <v-chip
        small
        class="v-chip-light-bg font-weight-semibold text-capitalize"
        v-bind="attrs"
        v-on="on"
      >
        {{ item.credit || 0 }} F
      </v-chip>
    </template>
    <v-card :loading="loading">
      <v-card-title class="headline">
        {{ $t("Confirm") }}
      </v-card-title>
      <v-card-text>
        {{ $t("Are you sure credit the user account") }}
        <v-text-field
          v-model="amount"
          type="number"
          outlined
          dense
          :label="$t('Amount')"
        />
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="error"
          outlined
          :loading="loading"
          @click="isDialogVisible = false"
        >
          {{ $t("Cancel") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :loading="loading"
          @click="onSubmit"
        >
          {{ $t("Continue") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiPencilOutline, mdiPencilOffOutline } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import useUsersList from './useUsersList'

export default {
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    showChip: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const {
      isSnackbarBottomVisible,
      snackBarMessage,
      resolveUserStatusVariant,
      resolveUserStatusName,
    } = useUsersList()
    const errorMessages = ref({ })
    const isDialogVisible = ref(false)
    const valid = ref(false)
    const amount = ref(500)
    const loading = ref(false)
    const isLoadingUsers = ref(false)

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    const onSubmit = () => {
      loading.value = true

      store.dispatch('app-user/addUserCredit', { user: item.value.id, amount: amount.value })
        .then(response => {
          loading.value = false
          isDialogVisible.value = false
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...response.data.errors }
            } else {
              isSnackbarBottomVisible.value = true
              snackBarMessage.value = response.data.message

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return false
          }

          // emit('update:is-form-active', false)

          emit('refetch-data')
        }).catch(error => {
          loading.value = false
          console.log('error', error)
          if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
            errorMessages.value = { ...error.data.errors }
          }
        })
    }
    // eslint-disable-next-line no-unused-vars
    /* watch(() => props.object, (newValue, prevValue) => {
      if (!newValue || !newValue.id) {
        resetItem()
      } else {
        item.value = JSON.parse(JSON.stringify(newValue))
      }
    }) */
    // eslint-disable-next-line no-unused-vars
    /* watch(() => item, (newValue, prevValue) => {
      console.log('item change', newValue.value)
      emit('input', newValue.value)
    }, { deep: true }) */

    return {
      amount,
      isLoadingUsers,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,
      countries,
      item,
      valid,
      loading,
      isDialogVisible,
      resolveUserStatusVariant,
      resolveUserStatusName,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
